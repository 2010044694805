import Model from './base';

export class SiteTextBlock extends Model {
  resource() {
    return 'site-text-blocks';
  }

  primaryKey() {
    return 'type';
  }

  defaultAttributes() {
    return {
      type: null,
      site_text_block_translations: [],
    }
  }
}
