<template>
  <div>
    <transition-group v-if="slidesTotal.desktop" tag="div" @after-leave="reverse.desktop = false"
                      :name="reverse.desktop ? 'slide-reverse' : 'slide'"
                      class="carousel-container carousel-desktop">
      <div v-if="slidesTotal.desktop > 1" key="navigation-buttons">
        <button key="prev" type="button" @click="previousSlide('desktop')"
                :aria-label="$t('prev_btn')" class="carousel-navigation-prev">
          <i class="svg-icon arrow"></i>
        </button>
        <button key="next" type="button" @click="nextSlide('desktop')"
                :aria-label="$t('next_btn')" class="carousel-navigation-next">
          <i class="svg-icon arrow"></i>
        </button>
        <ul key="pagination" class="carousel-dots">
          <li v-for="i in [...Array(slidesTotal.desktop).keys()]" @click="switchSlide(i, 'desktop')"
              :class="{'page-active': i === pageIndex.desktop}">
            <button type="button" :aria-label="i"></button>
          </li>
        </ul>
      </div>
      <div v-for="idx in [slideIndex.desktop]" class="carousel" :key="`desktop-slide-${idx}`">
        <section class="banner" :class="banner.desktop.theme">
          <div class="adaptive">
            <nuxt-img preload
                 :src="banner.desktop.image_desktop.url | baseUrl"
                 :width="banner.desktop.image_desktop.width"
                 :height="banner.desktop.image_desktop.height"
                 :alt="alt(banner.desktop.image)"
                 class="banner-image"
                 loading="lazy"/>
            <div class="container banner-caption" :class="banner.desktop.theme">
              <div v-html="caption(banner.desktop)"></div>
            </div>
            <button v-if="banner.desktop.link_page || linkExternal(banner.desktop)" class="button-link">
              <nuxt-link v-if="banner.desktop.link_page" :to="localePath(banner.desktop.link_page)"
                         class="link" :aria-label="$t('learn_more')">{{ $t('learn_more') }}</nuxt-link>
              <a v-else-if="linkExternal(banner.desktop)" :href="linkExternal(banner.desktop)"
                 class="link" :aria-label="$t('learn_more')">{{ $t('learn_more') }}</a>
            </button>
          </div>
        </section>
      </div>
    </transition-group>

    <transition-group v-if="slidesTotal.mobile" tag="div" @after-leave="reverse.mobile = false"
                      :name="reverse.mobile ? 'slide-reverse' : 'slide'"
                      class="carousel-container carousel-mobile">
      <template v-if="slidesTotal.mobile > 1">
        <ul key="pagination" class="carousel-dots">
          <li v-for="i in [...Array(slidesTotal.mobile).keys()]" @click="switchSlide(i, 'mobile')"
              :class="{'page-active': i === pageIndex.mobile}">
            <button type="button" :aria-label="i"></button>
          </li>
        </ul>
      </template>
      <div v-for="idx in [slideIndex.mobile]" class="carousel" :key="`mobile-slide-${idx}`">
        <section class="banner" :class="banner.mobile.theme">
          <div class="adaptive">
            <nuxt-link v-if="banner.mobile.link_page" 
              class="link" :aria-label="$t('learn_more')"
              :to="localePath(banner.mobile.link_page)"
              v-touch:swipe.left="swipeLeft"
              v-touch:swipe.right="swipeRight"
            >
              <nuxt-img preload
                :src="banner.mobile.image_mobile.url | baseUrl"
                :width="banner.mobile.image_mobile.width"
                :height="banner.mobile.image_mobile.height"
                :alt="alt(banner.mobile.image)"
                class="banner-image"
                loading="lazy"
              />

              <div class="container banner-caption" :class="banner.mobile.theme">
                <div v-html="caption(banner.mobile)" class="h1"></div>
              </div>
            </nuxt-link>
            <a v-else-if="linkExternal(banner.mobile)" :href="linkExternal(banner.mobile)"
               class="link" :aria-label="$t('learn_more')"
               v-touch:swipe.left="swipeLeft"
               v-touch:swipe.right="swipeRight"
            >
              <nuxt-img preload
                :src="banner.mobile.image_mobile.url | baseUrl"
                :width="banner.mobile.image_mobile.width"
                :height="banner.mobile.image_mobile.height"
                :alt="alt(banner.mobile.image)"
                class="banner-image"
                loading="lazy"
                />
              <div class="container banner-caption" :class="banner.mobile.theme">
                <div v-html="caption(banner.mobile)" class="h1"></div>
              </div>
            </a>
            <div v-else>
              <nuxt-img preload
                        :src="banner.mobile.image_mobile.url | baseUrl"
                        :width="banner.mobile.image_mobile.width"
                        :height="banner.mobile.image_mobile.height"
                        :alt="alt(banner.mobile.image)"
                        class="banner-image"
                        loading="lazy"
              />
              <div class="container banner-caption" :class="banner.mobile.theme">
                <div v-html="caption(banner.mobile)" class="h1"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </transition-group>
  </div>
</template>

<script>
const _animationDelay = 7000;
const _animationDuration = 700;

export default {
  props: ['page'],
  data() {
    return {
      reverse: {
        desktop: false,
        mobile: false,
      },
      interval: {
        desktop: null,
        mobile: null,
      },
      slideIndex: {
        desktop: 0,
        mobile: 0,
      },
      pageIndex: {
        desktop: 0,
        mobile: 0,
      },
    }
  },
  computed: {
    slidesTotal() {
      return {
        desktop: this.page.banner_items ? this.page.banner_items.filter(x => x.visible && x.image_desktop).length : 0,
        mobile: this.page.banner_items_mobile ? this.page.banner_items_mobile.filter(x => x.visible && x.image_mobile).length : 0,
      }
    },
    banner() {
      return {
        desktop: this.page.banner_items.filter(x => x.visible && x.image_desktop)[this.slideIndex.desktop],
        mobile: this.page.banner_items_mobile.filter(x => x.visible && x.image_mobile)[this.slideIndex.mobile],
      }
    },
  },
  mounted() {
    if (this.slidesTotal.desktop > 1) {
      this.startInterval('desktop');
    }
    if (this.slidesTotal.mobile > 1) {
      this.startInterval('mobile');
    }
  },
  destroyed() {
    this.stopInterval('desktop');
    this.stopInterval('mobile');
  },
  methods: {
    swipeLeft(e){
      // console.log(e)
      this.nextSlide('mobile')
    },
    swipeRight(e){
      // console.log(e)
      this.previousSlide('mobile')
    },
    alt(image) {
      return image.image_translations?.find(x => x.language.slug === this.$i18n.locale)?.title || image.title;
    },
    linkExternal(banner) {
      return banner.banner_item_translations?.find(x => x.language.slug === this.$i18n.locale)?.link_external || banner.link_external;
    },
    caption(banner) {
      return banner.banner_item_translations?.find(x => x.language.slug === this.$i18n.locale)?.caption || '';
    },
    startInterval(mode) {
      this.interval[mode] = setInterval(() => {
        if (this.slideIndex[mode] + 1 >= this.slidesTotal[mode]) {
          this.slideIndex[mode] = 0;
        } else {
          this.slideIndex[mode] += 1;
        }
        this.pageIndex[mode] = this.slideIndex[mode];
      }, _animationDelay);
    },
    stopInterval(mode) {
      clearInterval(this.interval[mode]);
    },
    previousSlide(mode) {
      let idx = this.slideIndex[mode] - 1;
      if (idx < 0) {
        idx = this.slidesTotal[mode] - 1;
      }
      this.pageIndex[mode] = idx;
      this.reverse[mode] = true;
      this.setSlide(idx, mode);
    },
    nextSlide(mode) {
      let idx = this.slideIndex[mode] + 1;
      if (idx >= this.slidesTotal[mode]) {
        idx = 0;
      }
      this.pageIndex[mode] = idx;
      this.setSlide(idx, mode);
    },
    async switchSlide(idx, mode) {
      const timer = ms => new Promise(res => setTimeout(res, ms));
      this.pageIndex[mode] = idx;
      if (idx < this.slideIndex[mode]) {
        let delay = _animationDuration / (this.slideIndex[mode] - idx);
        for (let i = this.slideIndex[mode]; i !== idx; i--) {
          this.reverse[mode] = true;
          this.setSlide(i - 1, mode);
          await timer(delay);
        }
      } else if (idx > this.slideIndex[mode]) {
        let delay = _animationDuration / (idx - this.slideIndex[mode]);
        for (let i = this.slideIndex[mode]; i !== idx; i++) {
          this.setSlide(i + 1, mode);
          await timer(delay);
        }
      }
    },
    setSlide(idx, mode) {
      this.stopInterval(mode);
      if (idx >= this.slidesTotal[mode]) {
        this.slideIndex[mode] = 0;
      } else if (idx < 0) {
        this.slideIndex[mode] = this.slidesTotal[mode] - 1;
      } else {
        this.slideIndex[mode] = idx;
      }
      this.startInterval(mode);
    },
  },
}
</script>
