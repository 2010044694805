<template>
  <div class="field" :class="{'error': errors && errors.length}">
    <label class="error-label" v-if="errors && errors.length && !value">{{ errors[0] }}</label>
    <input
      :value="value"
      type="text"
      :placeholder="text"
      @focusin="$emit('clear-errors')"
      @input="updateField"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :id="text"
    />
    <label :for="text" v-if="errors && errors.length && value">{{ errors[0] }}</label>
    <label :for="text" v-else-if="errors && errors.length && !value">{{ text }}</label>
    <label :for="text" v-else>{{ text }}</label>
  </div>
</template>

<script>
export default {
  name: 'FieldText',
  props: ['value', 'text', 'errors', 'disabled', 'autocomplete'],
  methods: {
    updateField(e) {
      this.$emit("input", e.target.value);
    }
  },
};
</script>
