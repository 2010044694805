<template>
  <div>
    <div v-if="siteTextBlock && textBlockLocaleData.text" v-html="textBlockLocaleData.text"
         class="site-text-block-desktop"></div>
    <div v-if="siteTextBlock && textBlockLocaleData.text_mobile" v-html="textBlockLocaleData.text_mobile"
         class="site-text-block-mobile"></div>
  </div>
</template>

<script>
import {SiteTextBlock} from '@/models/site-text-block';

export default {
  props: ['type'],
  data() {
    return {
      siteTextBlock: {},
    }
  },
  computed: {
    textBlockLocaleData() {
      return this.siteTextBlock.site_text_block_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
  },
  async fetch() {
    try {
      this.siteTextBlock = await SiteTextBlock.config({count_loading: true}).find(this.type);
    } catch (e) {
    }
  },
}
</script>
