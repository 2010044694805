<template>
  <masked-input :value="value" type="text" :mask="maskArray" :show-mask="showMask" :guide="true"
                :placeholder="text" @focusin="onPhoneFocus" @blur="onPhoneBlur" @input="updateField" />
</template>

<script>
export default {
  props: ['value', 'mask', 'text'],
  data() {
    return {
      showMask: false,
    }
  },
  computed: {
    maskArray() {
      return this.mask.split('').map(x => x === '_' ? /\d/ : x);
    },
  },
  methods: {
    updateField(value) {
      this.$emit('input', value);
    },
    onPhoneFocus(e) {
      this.showMask = true;
      this.$emit('clear-errors');
      const intervalId = setInterval(() => {
        if (this.value) {
          const maskCharIdx = this.value.indexOf('_');
          if (maskCharIdx > -1) {
            e.target.setSelectionRange(maskCharIdx, maskCharIdx);
          }
          clearInterval(intervalId);
        }
      }, 50);
    },
    onPhoneBlur() {
      this.showMask = false;
      if (this.value === this.mask) {
        this.updateField('');
      }
    },
  },
};
</script>
