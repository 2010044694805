<template>
  <div class="field field-textarea" :class="{'error': errors && errors.length}">
    <label class="error-label" v-if="errors && errors.length && !value">{{ errors[0] }}</label>
    <textarea :value="value" @input="updateField" @focusin="$emit('clear-errors')" spellcheck="false" :name="text"
              :placeholder="text"></textarea>
    <label v-if="errors && errors.length && value">{{ errors[0] }}</label>
    <label v-else>{{ text }}</label>
  </div>
</template>

<script>
export default {
  props: ['value', 'text', 'errors'],
  methods: {
    updateField(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>
