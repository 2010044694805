import Model from './base';

export class NewsItem extends Model {
  resource() {
    return 'news';
  }

  defaultAttributes() {
    return {
      body: [],
      slug: null,
      date: null,
      image: {},
      news_item_translations: [],
    }
  }
}
