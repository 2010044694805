import Model from './base';

export class Country extends Model {
  resource() {
    return 'user/country';
  }

  defaultAttributes() {
    return {
      code: null,
      alpha: null,
      alpha3: null,
      name_short: null,
      name_full: null,
      sort_order: null,
      inn_check: false,
      phone_mask: null,
      country_limits: [],
    }
  }
}
