<template>
  <div class="container contact-form">
    <h2>{{ formLocaleData.title }}</h2>
    <div v-if="formLocaleData.body" v-html="formLocaleData.body" class="form-body"></div>
    <form class="form" @submit.prevent="submitForm">
      <template v-for="field in form.form_fields">
        <div v-if="getFieldLocaleData(field).choices.length" class="field-v-select"
             :class="{'error': errors[`field${field.id}`] && errors[`field${field.id}`].length}">
          <label v-if="errors[`field${field.id}`] && errors[`field${field.id}`].length && !formData[`field${field.id}`]"
                 class="error-label">{{ errors[`field${field.id}`][0] }}</label>
          <v-select v-model="formData[`field${field.id}`]" :options="getFieldLocaleData(field).choices"
                    :searchable="false" :clearable="false"
                    @option:selected="clearErrors(`field${field.id}`)" :class="{'selected': !!formData[`field${field.id}`]}">
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"><i class="svg-icon arrow"></i></span>
            </template>
          </v-select>
          <label v-if="errors[`field${field.id}`] && errors[`field${field.id}`].length && formData[`field${field.id}`]">{{ errors[`field${field.id}`][0] }}</label>
          <label v-else-if="errors[`field${field.id}`] && errors[`field${field.id}`].length && !formData[`field${field.id}`]">{{ getFieldLocaleData(field).label }}</label>
          <label v-else>{{ getFieldLocaleData(field).label }}</label>
        </div>
        <div v-else-if="field.field_type === 'phone'" class="field-country-tel"
             :class="{'error': errors[`field${field.id}`] && errors[`field${field.id}`].length}">
          <v-select v-model="selectedCountries[field.id]" :options="countries.map(x => ({id: x.id, label: x.alpha}))"
                    :reduce="x => x.id" :searchable="false" :clearable="false">
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"><i class="svg-icon arrow"></i></span>
            </template>
          </v-select>
          <div class="field">
            <label v-if="errors[`field${field.id}`] && errors[`field${field.id}`].length && !formData[`field${field.id}`]" class="error-label">
              {{ errors[`field${field.id}`][0] }}
            </label>
            <input-phone v-if="selectedCountries[field.id]" v-model="formData[`field${field.id}`]" :value="formData[`field${field.id}`]"
                         :mask="countries.find(x => x.id === selectedCountries[field.id]).phone_mask"
                         @clear-errors="clearErrors(`field${field.id}`)" :text="getFieldLocaleData(field).label" />
            <label v-if="errors[`field${field.id}`] && errors[`field${field.id}`].length && formData[`field${field.id}`]">
              {{ errors[`field${field.id}`][0] }}
            </label>
            <label v-else>{{ getFieldLocaleData(field).label }}</label>
          </div>
        </div>
        <field-text v-else-if="['singleline', 'number', 'email'].includes(field.field_type)"
          v-model="formData[`field${field.id}`]"
          :value="formData[`field${field.id}`]"
          :text="getFieldLocaleData(field).label"
          :errors="errors[`field${field.id}`]"
          @clear-errors="clearErrors(`field${field.id}`)"
        />
        <field-textarea v-else-if="field.field_type === 'multiline'" v-model="formData[`field${field.id}`]"
                        :value="formData[`field${field.id}`]" :text="getFieldLocaleData(field).label" :errors="errors[`field${field.id}`]"
                        @clear-errors="clearErrors(`field${field.id}`)" />
      </template>

      <div class="checkboxes" v-if="form.privacy_checkbox || form.subscription_checkbox">
        <template v-if="form.privacy_checkbox">
          <div class="checkbox">
            <label :class="{'checked': privacy}">
              <input class="styled-checkbox" type="checkbox" v-model="privacy">{{
                formLocaleData.privacy_policy_text
              }}</label>
          </div>
        </template>


        <template v-if="form.subscription_checkbox">
          <div class="checkbox">
            <label :class="{'checked': subscription}">
              <input class="styled-checkbox" type="checkbox" v-model="subscription">{{
                formLocaleData.subscriptions_text
              }}</label>
          </div>
        </template>
      </div>

      <div class="captcha-links">
        <span v-if="errors.recaptcha && errors.recaptcha.length" class="error-captcha">
          {{ $t('captcha_error') }}
        </span>
        <site-text-block type="form_submit" class="submit-text" />
        <button type="submit">{{ $t('submit') }}</button>
      </div>
    </form>
    <modal :visible="showFormSubmittedModal" @close="showFormSubmittedModal = false">
      <template v-slot:header>
        <div></div>
      </template>
      <template v-slot:body>
        <div v-html="formLocaleData.thank_you_text"></div>
      </template>
      <template v-slot:footer>
        <button class="modal-default-button" @click="showFormSubmittedModal = false">{{ $t('continue') }}</button>
      </template>
    </modal>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import {Country} from '@/models/country';

import FieldText from '@/components/field-text';
import FieldTextarea from '@/components/field-textarea';
import InputPhone from '@/components/input-phone';
import Modal from '@/components/modal';
import SiteTextBlock from '@/components/blocks/site-text-block';

export default {
  components: {
    FieldText,
    FieldTextarea,
    InputPhone,
    Modal,
    SiteTextBlock,
    vSelect,
  },
  name: 'ContactForm',
  props: ['form'],
  data() {
    return {
      formData: {},
      errors: {},
      showFormSubmittedModal: false,
      countries: [],
      selectedCountries: {}, // {'field ID': 'country ID'}
      privacy: false,
      subscription: false,
    }
  },
  watch: {
    copySelectedCountries: {
      handler(newValue, oldValue) {
        for (const [fieldId, newCountryId] of Object.entries(newValue)) {
          const newCountry = this.countries.find(x => x.id === newCountryId);
          const oldCountry = this.countries.find(x => x.id === oldValue[fieldId]);
          if (newCountry && oldCountry && newCountry.phone_mask !== oldCountry.phone_mask) {
            this.formData[`field${fieldId}`] = null;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    copySelectedCountries() {
      return {...this.selectedCountries};
    },
    formLocaleData() {
      return this.form.site_form_translations?.find(x => x.language.slug === this.$i18n.locale) || {};
    },
  },
  async fetch() {
    this.countries = await Country.config({count_loading: true}).get();
    this.initForm();
  },
  beforeDestroy() {
    this.$recaptcha.destroy();
  },
  methods: {
    getFieldLocaleData(field) {
      return field.form_field_translations?.find(x => x.language.slug === this.$i18n.locale) || {
        choices: [],
      };
    },
    initForm() {
      this.formData = {};
      if (this.form.captcha) {
        this.formData.recaptcha = null;
      }
      for (const field of this.form.form_fields) {
        this.$set(this.formData, `field${field.id}`, this.getFieldLocaleData(field).default_value);
      }
      const phoneFields = this.form.form_fields.filter(x => x.field_type === 'phone');
      if (phoneFields.length && this.countries.length) {
        for (const field of phoneFields) {
          this.$set(this.selectedCountries, field.id, this.countries[0].id);
        }
      }
    },
    clearErrors(field) {
      this.errors[field] = [];
    },
    async submitForm() {
      try {
        if (this.form.privacy_checkbox) {
          if (!this.privacy) {
            return
          }
        }

        if (this.subscription) {
          this.formData.subscription = true;
        } else {
          this.formData.subscription = false;
        }

        if (this.form.captcha) {
          this.formData.recaptcha = await this.$recaptcha.execute('site_form');
        }
        await this.$axios.$post(`/site-forms/${this.form.id}/submit-form/`, this.formData);
        this.showFormSubmittedModal = true;
        this.initForm();
      } catch (e) {
        try {
          this.errors = e.response.data;
        } catch (e) {
          this.$notify({
            duration: 5000,
            text: this.$t('unknown_error'),
          });
        }
      }
    },
  },
}
</script>
